cx-future-stock-accordion {
  display: block;
  overflow: hidden;
  margin-bottom: 1.25rem;

  .cx-future-stock-accordion-header {
    border: none;
    background-color: transparent;
    padding-inline-start: 1.25rem;
    @include forFeature('a11yCroppedFocusRing') {
      padding-inline-start: unset;
      margin-inline-start: 1.25rem;
      padding: 0;

      @include media-breakpoint-down(md) {
        margin-inline-start: 0;
      }
    }
    margin: 0.5rem auto;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    &:hover {
      color: var(--cx-color-primary);
    }

    cx-icon {
      padding-inline-start: 0.375rem;
    }
  }

  .cx-future-stock-accordion-content {
    padding-inline-start: 1.25rem;
    margin-bottom: 1rem;
  }
}
