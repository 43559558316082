//Fonts must be added separate from the theme
@import '@spartacus/styles/scss/fonts';

// Bootstrap modules (these are things we are NOT changing at all)
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/nav';
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";
@import '@fortawesome/fontawesome-free/css/all.css';

@import '@ng-select/ng-select/themes/default.theme.css';

// new
@import '@spartacus/styles/scss/cxbase/index';

//bootstrap enhancements (these are things we ARE changing)
@import '@spartacus/styles/scss/cxbase/blocks/buttons';
// @import '@spartacus/styles/scss/cxbase/blocks/forms';
@import '@spartacus/styles/scss/cxbase/blocks/modal';
// @import '@spartacus/styles/scss/cxbase/blocks/alert';
// @import '@spartacus/styles/scss/cxbase/blocks/message';
@import '@spartacus/styles/scss/cxbase/blocks/tooltip';
@import '@spartacus/styles/scss/cxbase/blocks/tables';
@import '@spartacus/styles/scss/cxbase/blocks/card';
@import '@spartacus/styles/scss/cxbase/blocks/accordion';
@import '@spartacus/styles/scss/cxbase/blocks/tabs';
@import '@spartacus/styles/scss/cxbase/blocks/carousel';
@import '@spartacus/styles/scss/cxbase/blocks/link';
@import '@spartacus/styles/scss/cxbase/blocks/dropdown';

//layout
@import '@spartacus/styles/scss/cxbase/layout/app';
@import '@spartacus/styles/scss/cxbase/layout/page';
@import '@spartacus/styles/scss/cxbase/layout/section';
//non-bootstrap blocks and components here below
@import '@spartacus/styles/scss/cxbase/blocks/paragraph';

// effects & animations
@import '@spartacus/styles/scss/cxbase/animations';

@import '@spartacus/styles/scss/misc/hamburger';
@import '@spartacus/styles/scss/misc/dialog';
@import '@spartacus/styles/scss/misc/table';